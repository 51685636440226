/* Big Title */

.bigTitle {
  width: 100%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 900px) {
  .bigTitle {
    width: auto;
  }
}

/* Video Section Component componet */

.videoSection {
  z-index: 20;
  width: 100%;
  height: 100vh;
  min-height: 600px;
  position: relative;
  overflow: hidden;
  background-color: rgba(206, 208, 237, 1);
  display: flex;
  flex-direction: row;
}

.videoSection video {
  position: absolute;
  z-index: 10;
  background-size: 100% 100%;
  margin-left: 50vw;
  transform: translateX(-50%) translateY(-50%);
  margin-top: 50vh;
  top: 0px;
  left: 0px; /* fixed to left. Replace it by right if you want.*/
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -5;
  opacity: 1;
}

.videoSection .fullScreenImage {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-size: cover;
}

@media only screen and (max-width: 900px) {
  .videoSection .fullScreenImage {
    background-repeat: no-repeat;
    background-position: 30% 0;
  }
}

.videoOverlay {
  width: 100%;
  height: 100%;
  z-index: 15;
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(256, 256, 256, 1) 0%,
    rgba(256, 256, 256, 0.4) 20%,
    rgba(256, 256, 256, 0) 40%,
    rgba(256, 256, 256, 0) 70%,
    rgba(256, 256, 256, 0.8) 85%,
    rgba(256, 256, 256, 1) 100%
  );
}

.projectOverlay {
  width: 100%;
  height: 100%;
  z-index: 15;
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(256, 256, 256, 1) 1%,
    rgba(256, 256, 256, 0.7) 20%,
    rgba(256, 256, 256, 0) 40%,
    rgba(256, 256, 256, 0) 100%
  );
}

.videoSectionLeft {
  width: 70%;
  height: 100%;
  position: relative;
}

.videoSectionRight {
  width: 30%;
  min-width: 300px;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 30px;
}

.seiteInfo {
  z-index: 30;
  width: 300px;
  height: 82%;
  max-height: 1200px;
  right: 30px;
  display: flex;
  flex-direction: column;
  background: rgba(256, 256, 256, 0.3);
  border-radius: 16px;
  padding: 50px 30px 30px 30px;
  backdrop-filter: blur(30px);
}

.seiteTitle,
.seiteText {
  margin-bottom: 15px;
}

.seiteButtons {
  bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .videoSection {
    flex-direction: column;
  }

  .videoOverlay {
    background: linear-gradient(
      0deg,
      rgba(256, 256, 256, 1) 0%,
      rgba(256, 256, 256, 0) 35%,
      rgba(256, 256, 256, 0.7) 50%,
      rgba(256, 256, 256, 0) 65%,
      rgba(256, 256, 256, 0.8) 90%,
      rgba(256, 256, 256, 1) 100%
    );
  }

  .projectOverlay {
    background: linear-gradient(
      0deg,
      rgba(256, 256, 256, 1) 1%,
      rgba(256, 256, 256, 0.5) 50%,
      rgba(256, 256, 256, 0) 70%,
      rgba(256, 256, 256, 0) 100%
    );
  }

  .videoSectionLeft {
    width: 100%;
    height: 55%;
  }

  .videoSectionRight {
    width: 100%;
    height: 40%;
    padding: 15px;
  }

  .seiteInfo {
    width: 100%;
    height: auto;
    padding: 30px 20px 20px 20px;
  }

  .seiteButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}

/* Tiken module (tesla style stat head-up) */
.tikenModule {
  z-index: 100;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  bottom: 50px;
}

.tiken h2 {
  color: inherit;
  text-align: center;
  margin-bottom: 5px;
}

.tiken h6 {
  color: inherit;
  text-align: center;
}

.tiken {
  max-width: 150px;
  padding: 0 30px;
}

.secondTiken {
  border-right: 1px solid #0e0e0e;
  border-left: 1px solid #0e0e0e;
}

@media only screen and (max-width: 450px) {
  .tikenModule {
    bottom: 30px;
  }

  .tiken {
    max-width: 100px;
    padding: 0 15px;
  }
}

/* Play Button componet */

.playButtonContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playButton {
  width: 100px;
  height: 100px;
  border-radius: 300px;
  border: 1px solid #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  user-select: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Simple  Button componet */

.simpleBtn {
  border-radius: 60px;
  border: 2px solid #060606;
  color: #060606;
  transition: all 0.6s ease;
  cursor: pointer;
  text-align: center;
  background-color: rgba(256, 256, 256, 0);
  margin: 10px 0px 10px 0;
}

.simpleBtn h4 {
  padding: 10px 20px;
  color: inherit;
  text-align: center;
  white-space: nowrap;
}

.simpleDarkBtn {
  border-color: #060606;
  color: #060606;
}

.simpleLightBtn {
  border-color: #f6f6f6;
  color: #f6f6f6;
}

.simpleBtnDark:hover {
  background-color: #060606;
  color: #f6f6f6;
}

.simpleLightBtn:hover {
  background-color: #f6f6f6;
  color: #060606;
}

/*Circle Button componet */

.circleBtn {
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 10px 0px 10px 0;
  white-space: nowrap;
}

.circleBtnIcon {
  margin-right: 10px;
  border-radius: 20px;
  border: 2px solid;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  font-weight: 900;
  color: inherit;
}

.circleBtnDark {
  border-color: #060606;
  color: #060606;
}

.circleBtnLight {
  border-color: #f6f6f6;
  color: #f6f6f6;
}

.circleBtnDark:hover .circleBtnIcon {
  background-color: #060606;
  color: #f6f6f6;
  border-color: #060606;
}

.circleBtnLight:hover .circleBtnIcon {
  background-color: #f6f6f6;
  color: #060606;
  border-color: #f6f6f6;
}

/* FullScreenEffect Carousel component */

.defaultCarousel {
  z-index: 200;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.4s;
  height: 100%;
}

.defaultCarouselItemWrapper {
  position: absolute;
  height: 100%;
  padding: 0px;
  transition: all 0.6s;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.defaultCarouselItem {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.defaultCarouselControl {
  z-index: 200;
  position: absolute;
  width: 150px;
  height: 50%;
  top: 25%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.next {
  right: 0;
}

.previous {
  margin-left: 0;
}

@media only screen and (max-width: 1023px) {
  .defaultCarouselControl {
    display: none;
  }

  .defaultCarouselItemWrapper {
    transition: all 0.2s ease;
  }
}

/* Filter Blur Img Component */

.filterBlurImgContainer {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
}

.filterBlurImg {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 5;
}

.filterBlurImgBottom {
  position: absolute;
  top: 0;
  z-index: 10 !important;
  -webkit-mask-image: -webkit-linear-gradient(
    90deg,
    rgba(256, 256, 256, 1) 20%,
    rgba(256, 256, 256, 0) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  -webkit-filter: blur(3px);
}

.filterDark {
  -webkit-filter: blur(3px) brightness(50%) !important;
}

.filterLight {
  -webkit-filter: blur(3px) brightness(150%) !important;
}

/* Contact Us Form*/

.contactFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 100px 0;
  padding: 50px;
  color: #060606;
  position: relative;
  border-radius: 50px;
  background: rgba(189, 202, 251, 0.1);
  backdrop-filter: blur(16px);
}

.contactFormContainer h3 {
  padding: 5px 0px 10px 0px;
}

.contactFormContainer p {
  max-width: 450px;
}

.contactForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.form-group {
  width: 100%;
  margin-top: 20px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  background-color: rgba(192, 192, 192, 0);
  padding: 6px;
  font-size: 18px;
  border: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-top: 5px;
  outline: 0;
  transition: 0.3s ease border-bottom;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 28px;
  font-family: "Raleway", sans-serif;
  margin: 0;
}

.form-group input:focus,
.form-group textarea:focus {
  border-bottom: 1px solid rgba(189, 202, 251, 1);
}

textarea {
  resize: vertical;
}

.contactForm button {
  margin-top: 20px;
}

::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 28px;
  font-family: "Raleway", sans-serif;
  margin: 0;
}

/* Background svg decorations */

.backSvg {
  position: absolute;
  z-index: 5;
  opacity: 0.2;
}

.backRightBottom {
  bottom: -20%;
  right: -5%;
  transform: rotate(30deg);
}

.backLeftBottom {
  bottom: -20%;
  left: 5%;
  transform: rotate(-15deg);
}

.backRightTop {
  top: -10%;
  right: 10%;
  transform: rotate(-25deg);
}
