.servicesContainer {
  width: 100%;
  height: 500px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  background-color: #f6f6f6;
  border-radius: 30px;
  overflow: hidden;
}

.socialPostContainer {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  padding-bottom: 66px;
  transition: all 0.4s ease;
  overflow: hidden;
}

.servicePage .socialPostContainer {
  border-radius: 30px;
  height: 250px;
  margin-top: 100px;
}

.socialPostContainer:hover .circleBtnIcon {
  background-color: rgba(256, 256, 256, 0.3);
  color: #f6f6f6;
  border-color: #f6f6f6;
}

@media only screen and (min-width: 451px) {
  .socialPostContainer:hover > .socialPost {
    filter: drop-shadow(-60px 60px 60px rgb(0, 0, 0, 0.11));
    transform: scale(1.2) rotateX(60deg) rotateY(0deg) rotateZ(-45deg)
      translateZ(50px);
  }
}

.serviceName {
  position: absolute;
  z-index: 30;
  top: 50px;
  left: 50px;
  width: 100px;
  color: #fff;
}

.serviceName h5 {
  white-space: nowrap;
}

.plusButton {
  position: absolute;
  bottom: 50px;
  left: 50px;
}

.transparentBlueGradientBack {
  background: rgba(189, 202, 251, 0.1);
}

.redGradientBack {
  background: linear-gradient(
    130deg,
    rgba(215, 102, 98, 1) 0%,
    rgba(244, 181, 161, 0.7) 100%
  );
}

.pinkGradientBack {
  background: linear-gradient(
    130deg,
    rgba(221, 129, 137, 1) 0%,
    rgba(252, 197, 215, 0.7) 100%
  );
}

.violetGradientBack {
  background: linear-gradient(
    130deg,
    rgba(221, 168, 232, 1) 0%,
    rgba(249, 215, 253, 0.7) 100%
  );
}

.socialPost1 {
  grid-column: 1/2;
  grid-row: 1/3;
  z-index: 3;
}
.socialPost2 {
  grid-column: 2/3;
  grid-row: 1/2;

  z-index: 2;
}

.socialPost3 {
  grid-column: 2/3;
  grid-row: 2/3;
  z-index: 1;
}

.socialPost1 .socialPost {
  position: absolute;
  bottom: 0px;
  right: 50px;
}

.socialPost2 .digitalCreations {
  position: absolute;
  bottom: -30px;
  right: -30px;
}

.socialPost3 .socialManagement {
  position: absolute;
  bottom: -250px;
  right: -150px;
}

.socialPost {
  width: 200px;
  height: 280px;
  background-color: #fff;
  border-radius: 30px;
  overflow: hidden;
  position: absolute;
  animation: socialPostAnimation 6s ease-in;
  animation-iteration-count: 9;
  transition: all 0.3s ease;
  transform: scale(1.2) rotateX(60deg) rotateY(0deg) rotateZ(-45deg)
    translateZ(0px);
}

@media only screen and (max-width: 900px) and (min-height: 600px),
  (orientation: portrait) {
  .servicesContainer {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    min-height: 600px;
  }

  .serviceName {
    position: absolute;
    z-index: 30;
    top: 25px;
    left: 25px;
    width: 100px;
    color: #fff;
  }

  .plusButton {
    bottom: 25px;
    left: 25px;
  }

  .socialPost {
    transform: scale(0.8) rotateX(60deg) rotateY(0deg) rotateZ(-45deg)
      translateZ(0px) !important;
  }

  .socialPost1 {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .socialPost2 {
    grid-column: 1/2;
    grid-row: 2/3;
  }

  .socialPost3 {
    grid-column: 1/2;
    grid-row: 3/4;
  }

  .socialPost1 .socialPost {
    bottom: -50px;
    right: 0px;
  }
}

@keyframes socialPostAnimation {
  0% {
    width: 200px;
    height: 280px;
  }
  27% {
    width: 200px;
    height: 280px;
  }
  30% {
    width: 200px;
    height: 340px;
  }
  31% {
    width: 200px;
    height: 310px;
  }
  33% {
    width: 200px;
    height: 320px;
  }
  60% {
    width: 200px;
    height: 320px;
  }
  63% {
    width: 200px;
    height: 160px;
  }
  64% {
    width: 200px;
    height: 190px;
  }
  66% {
    width: 200px;
    height: 180px;
  }
  93% {
    width: 200px;
    height: 180px;
  }
  97% {
    width: 200px;
    height: 300px;
  }
  98% {
    width: 200px;
    height: 270px;
  }
  100% {
    width: 200px;
    height: 280px;
  }
}

.socialPost3D {
  z-index: 30;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  box-shadow: inset 4px -4px 4px rgba(215, 82, 98, 0.6);
  filter: drop-shadow(-12px 12px 24px rgba(215, 102, 98, 1));
}

.socialProfile {
  z-index: 10;
  width: 30px;
  height: 30px;
  background: linear-gradient(
    130deg,
    rgba(215, 102, 98, 1) 0%,
    rgba(244, 181, 161, 1) 100%
  );
  border-radius: 30px;
  overflow: hidden;
  position: absolute;
  top: 15px;
  left: 15px;
  animation: socialProfileAnimation 6s ease-in;
  animation-iteration-count: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes socialProfileAnimation {
  0% {
    top: 15px;
    left: 15px;
  }
  27% {
    top: 15px;
    left: 15px;
    width: 30px;
  }
  30% {
    top: 100px;
    left: 165px;
    width: 25px;
  }
  31% {
    top: 85px;
    left: 150px;
    width: 32px;
  }
  33% {
    top: 90px;
    left: 155px;
    width: 30px;
  }
  60% {
    top: 90px;
    left: 155px;
    width: 30px;
  }
  63% {
    top: 145px;
    left: 10px;
    width: 25px;
  }
  64% {
    top: 130px;
    left: 20px;
    width: 32px;
  }
  66% {
    top: 135px;
    left: 15px;
    width: 30px;
  }
  93% {
    top: 135px;
    left: 15px;
    height: 30px;
  }
  97% {
    top: 10px;
    left: 15px;
    height: 25px;
  }
  98% {
    top: 20px;
    left: 15px;
    height: 32px;
  }
  100% {
    top: 15px;
    left: 15px;
    height: 30px;
  }
}

.socialImageContainer {
  width: 180px;
  height: 180px;
  background: linear-gradient(
    130deg,
    rgba(215, 102, 98, 1) 0%,
    rgba(244, 181, 161, 1) 100%
  );
  border-radius: 30px;
  position: absolute;
  top: 50px;
  left: 10px;
  animation: socialImageAnimation 6s ease-in;
  animation-iteration-count: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes socialImageAnimation {
  0% {
    top: 50px;
    left: 10px;
    width: 180px;
    height: 180px;
  }
  27% {
    top: 50px;
    left: 10px;
    width: 180px;
    height: 180px;
  }
  30% {
    top: 0px;
    left: 0px;
    width: 200px;
    height: 320px;
  }
  31% {
    top: 0px;
    left: 0px;
    width: 200px;
    height: 320px;
  }
  33% {
    top: 0px;
    left: 0px;
    width: 200px;
    height: 320px;
  }
  60% {
    top: 0px;
    left: 0px;
    width: 200px;
    height: 320px;
  }
  63% {
    top: 15px;
    left: 10px;
    width: 180px;
    height: 110px;
  }
  64% {
    top: 15px;
    left: 10px;
    width: 180px;
    height: 110px;
  }
  66% {
    top: 15px;
    left: 10px;
    width: 180px;
    height: 110px;
  }
  93% {
    top: 15px;
    left: 10px;
    width: 180px;
    height: 110px;
  }
  97% {
    top: 50px;
    left: 10px;
    width: 180px;
    height: 180px;
  }
  98% {
    top: 50px;
    left: 10px;
    width: 180px;
    height: 180px;
  }
  100% {
    top: 50px;
    left: 10px;
    width: 180px;
    height: 180px;
  }
}

.socialAction {
  z-index: 20;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 30px;
  background: linear-gradient(
    130deg,
    rgba(215, 102, 98, 1) 0%,
    rgba(244, 181, 161, 1) 100%
  );
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialLike {
  top: 240px;
  left: 15px;
  animation: socialLikeAnimation 6s ease-in;
  animation-iteration-count: 9;
}

.socialComment {
  top: 240px;
  left: 40px;
  animation: socialCommentAnimation 6s ease-in;
  animation-iteration-count: 9;
}

.socialShare {
  top: 240px;
  left: 65px;
  animation: socialShareAnimation 6s ease-in;
  animation-iteration-count: 9;
}

@keyframes socialLikeAnimation {
  0% {
    top: 240px;
    left: 15px;
  }
  27% {
    top: 240px;
    left: 15px;
  }
  33% {
    top: 130px;
    left: 160px;
  }
  60% {
    top: 130px;
    left: 160px;
  }
  66% {
    top: 140px;
    left: 110px;
  }
  93% {
    top: 140px;
    left: 110px;
  }
  96% {
    top: 220px;
    left: 10px;
  }
  100% {
    top: 240px;
    left: 15px;
  }
}

@keyframes socialCommentAnimation {
  0% {
    top: 240px;
    left: 40px;
  }
  27% {
    top: 240px;
    left: 40px;
  }
  33% {
    top: 155px;
    left: 160px;
  }
  60% {
    top: 155px;
    left: 160px;
  }
  66% {
    top: 140px;
    left: 135px;
  }
  93% {
    top: 140px;
    left: 135px;
  }
  96% {
    top: 220px;
    left: 30px;
  }
  100% {
    top: 240px;
    left: 40px;
  }
}

@keyframes socialShareAnimation {
  0% {
    top: 240px;
    left: 65px;
  }
  27% {
    top: 240px;
    left: 65px;
  }
  33% {
    top: 180px;
    left: 160px;
  }
  60% {
    top: 180px;
    left: 160px;
  }
  66% {
    top: 140px;
    left: 160px;
  }
  93% {
    top: 140px;
    left: 160px;
  }
  96% {
    top: 220px;
    left: 55px;
  }
  100% {
    top: 240px;
    left: 65px;
  }
}

/* Digital Creations style and aniamtions */

.digitalCreations {
  width: 320px;
  margin-top: 50px;
  position: relative;
  transition: all 0.3s ease;
  transform: rotateX(60deg) rotateY(0deg) rotateZ(-45deg) translateZ(0px);
  filter: drop-shadow(-12px 12px 15px rgb(221, 129, 137, 1));
  display: flex;
}

.socialPostContainer:hover > .digitalCreations {
  filter: drop-shadow(-60px 60px 30px rgb(0, 0, 0, 0.15));
  transform: rotateX(60deg) rotateY(0deg) rotateZ(-45deg) translateZ(50px);
}

.digitalPc {
  position: relative;
  width: 260px;
  height: 180px;
  background: linear-gradient(
    130deg,
    rgba(221, 129, 137, 0.5) 0%,
    rgba(252, 197, 215, 1) 100%
  );
  border-radius: 30px;
  box-shadow: inset 4px -4px 4px rgb(221, 129, 137, 1);
  overflow: hidden;
  animation: digitalPcAnim 6s ease-in;
  animation-iteration-count: 9;
}

@keyframes digitalPcAnim {
  0% {
    width: 260px;
    height: 180px;
    margin-left: 40px;
  }
  27% {
    width: 260px;
    height: 180px;
    margin-left: 40px;
  }
  33% {
    width: 260px;
    height: 180px;
    margin-left: 40px;
  }
  60% {
    width: 260px;
    height: 180px;
    margin-left: 40px;
  }
  62% {
    width: 170px;
    height: 180px;
    margin-left: -10px;
  }
  64% {
    width: 200px;
    height: 180px;
    margin-left: 5px;
  }
  66% {
    width: 190px;
    height: 180px;
    margin-left: 0px;
  }
  93% {
    width: 190px;
    height: 180px;
    margin-left: 0px;
  }
  95% {
    width: 280px;
    height: 180px;
    margin-left: 50px;
  }
  97% {
    width: 250px;
    height: 180px;
    margin-left: 30px;
  }
  100% {
    width: 260px;
    height: 180px;
    margin-left: 40px;
  }
}

.digitalPhone {
  position: relative;
  width: 0px;
  height: 180px;
  background: linear-gradient(
    130deg,
    rgba(221, 129, 137, 0.5) 0%,
    rgba(252, 197, 215, 1) 100%
  );
  border-radius: 30px;
  box-shadow: inset 4px -4px 4px rgb(252, 197, 215, 1);
  overflow: hidden;
  animation: digitalPhoneAnim 6s ease-in;
  animation-iteration-count: 9;
  margin-left: 20px;
}

@keyframes digitalPhoneAnim {
  0% {
    width: 0px;
    height: 180px;
  }
  27% {
    width: 0px;
    height: 180px;
  }
  33% {
    width: 0px;
    height: 180px;
  }
  60% {
    width: 0px;
    height: 180px;
  }
  62% {
    width: 130px;
    height: 180px;
  }
  64% {
    width: 100px;
    height: 180px;
  }
  66% {
    width: 110px;
    height: 180px;
  }
  93% {
    width: 110px;
    height: 180px;
  }
  95% {
    width: 0px;
    height: 180px;
  }
  97% {
    width: 20px;
    height: 180px;
  }
  100% {
    width: 0px;
    height: 180px;
  }
}

.phoneBox {
  width: 90px;
  margin-left: 10px;
  margin-top: 10px;
  height: 160px;
  background: #fff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBox {
  width: 100px;
  height: 25px;
  margin-left: 90px;
  margin-top: 70px;
  background: #fff;
  border-radius: 30px;
  position: reative;
  animation: searchBoxAnim 6s ease-in;
  animation-iteration-count: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes searchBoxAnim {
  0% {
    width: 100px;
    height: 25px;
    margin-left: 90px;
    margin-top: 70px;
  }
  27% {
    width: 100px;
    height: 25px;
    margin-left: 90px;
    margin-top: 70px;
  }
  33% {
    width: 220px;
    height: 25px;
    margin-left: 20px;
    margin-top: 10px;
  }
  60% {
    width: 220px;
    height: 25px;
    margin-left: 20px;
  }
  66% {
    width: 160px;
    height: 25px;
    margin-left: 20px;
    margin-top: 10px;
  }
  93% {
    width: 160px;
    height: 25px;
    margin-left: 20px;
    margin-top: 10px;
  }
  100% {
    width: 100px;
    height: 25px;
    margin-left: 90px;
    margin-top: 70px;
  }
}

.webBox {
  width: 240px;
  height: 120px;
  margin-left: 10px;
  margin-top: 10px;
  background: #fff;
  border-radius: 30px;
  margin-left: 10px;
  margin-top: 10px;
  animation: webBoxAnim 6s ease-in;
  animation-iteration-count: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes webBoxAnim {
  0% {
    width: 0px;
    height: 25px;
    margin-left: 120px;
    margin-top: 100px;
  }
  27% {
    width: 0px;
    height: 25px;
    margin-left: 120px;
    margin-top: 100px;
  }
  33% {
    width: 220px;
    height: 120px;
    margin-left: 20px;
    margin-top: 10px;
  }
  60% {
    width: 220px;
    height: 120px;
    margin-left: 20px;
    margin-top: 10px;
  }
  66% {
    width: 160px;
    height: 120px;
    margin-left: 20px;
    margin-top: 10px;
  }
  93% {
    width: 160px;
    height: 120px;
    margin-left: 20px;
    margin-top: 10px;
  }
  100% {
    width: 0px;
    height: 0px;
    margin-left: 120px;
    margin-top: 100px;
  }
}

/* Social media management styles and animations */

.socialManagement {
  position: relative;
  width: 600px;
  height: 600px;
  position: relative;
  transition: all 0.3s ease;
  transform: rotateX(60deg) rotateY(0deg) rotateZ(-45deg) translateZ(0px);
  filter: drop-shadow(-12px 12px 15px rgb(221, 168, 232, 1));
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialPostContainer:hover > .socialManagement {
  filter: drop-shadow(-60px 60px 30px rgb(221, 168, 232, 1));
  transform: rotateX(60deg) rotateY(0deg) rotateZ(-45deg) translateZ(50px);
}

.socialManagementContent {
  position: relative;
  width: 320px;
  height: 320px;
  display: flex;
}

.iconBox {
  position: absolute;
  width: 140px;
  height: 140px;
  background: grey;
  box-shadow: inset 4px -4px 4px rgba(221, 168, 232, 1);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.instaBox {
  z-index: 100;
  left: 160px;
  animation: InstaBoxAnim 6s ease;
  animation-iteration-count: 9;
  background: linear-gradient(
    130deg,
    rgba(221, 168, 232, 1) 0%,
    rgba(249, 215, 253, 1) 100%
  );
}

.youtubeBox {
  top: 160px;
  animation: youtubeBoxAnim 6s ease;
  animation-iteration-count: 9;
  background: linear-gradient(
    130deg,
    rgba(221, 168, 232, 1) 0%,
    rgba(249, 215, 253, 1) 100%
  );
}

.tiktokBox {
  animation: tiktokFacebookBoxAnim 6s ease;
  animation-iteration-count: 9;
  background: linear-gradient(
    130deg,
    rgba(221, 168, 232, 1) 0%,
    rgba(249, 215, 253, 1) 100%
  );
}

.facebookBox {
  left: 160px;
  top: 160px;
  animation: tiktokFacebookBoxAnim 6s ease;
  animation-iteration-count: 9;
  background: linear-gradient(
    130deg,
    rgba(221, 168, 232, 1) 0%,
    rgba(249, 215, 253, 1) 100%
  );
}

@keyframes InstaBoxAnim {
  0% {
    left: 0px;
    width: 320px;
    height: 320px;
  }
  27% {
    left: 0px;
    width: 320px;
    height: 320px;
  }
  30% {
    left: 180px;
    width: 120px;
    height: 120px;
  }
  31% {
    left: 150px;
    width: 150px;
    height: 150px;
  }
  33% {
    left: 160px;
    width: 140px;
    height: 140px;
  }
  60% {
    left: 160px;
    width: 140px;
    height: 140px;
  }
  63% {
    left: 160px;
    width: 140px;
    height: 140px;
  }
  93% {
    left: 160px;
    width: 140px;
    height: 140px;
  }
  97% {
    left: -20px;
    width: 340px;
    height: 340px;
  }
  98% {
    left: 10px;
    width: 310px;
    height: 310px;
  }
  100% {
    left: 0px;
    width: 320px;
    height: 320px;
  }
}

@keyframes youtubeBoxAnim {
  0% {
    transform: scale(0);
  }
  27% {
    transform: scale(0);
  }
  30% {
    transform: scale(1.2);
  }
  31% {
    transform: scale(0.9);
  }
  33% {
    transform: scale(1);
  }
  93% {
    transform: scale(1);
  }
  97% {
    transform: scale(0.1);
  }
  98% {
    transform: scale(0.3);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes tiktokFacebookBoxAnim {
  0% {
    transform: scale(0);
  }
  27% {
    transform: scale(0);
  }
  30% {
    transform: scale(0);
  }
  31% {
    transform: scale(0);
  }
  33% {
    transform: scale(0);
  }
  60% {
    transform: scale(0);
  }
  63% {
    transform: scale(1.2);
  }
  64% {
    transform: scale(0.9);
  }
  66% {
    transform: scale(1);
  }
  93% {
    transform: scale(1);
  }
  97% {
    transform: scale(0.1);
  }
  98% {
    transform: scale(0.3);
  }
  100% {
    transform: scale(0);
  }
}

/* Social Header Variation */

.socialHeader {
  width: 100%;
  height: 95vh;
  min-height: 600px;
  background: radial-gradient(
    circle,
    rgba(206, 208, 237, 0.1) 0%,
    rgba(206, 208, 237, 0.3) 30%,
    rgba(255, 255, 255, 1) 100%
  );
  margin-bottom: 50px;
}

.socialHeaderTitle {
  z-index: 30;
  position: absolute;
  top: 100px;
}

@media only screen and (max-width: 450px) {
  .socialHeaderTitle {
    max-width: 300px;
  }

  .socialHeader {
    height: 90vh;
  }

  .socialHeaderCallToAction {
    bottom: 120px !important;
  }
}

.socialHeaderTitle h1 {
  text-align: center;
  color: #060606;
}

.socialHeaderTitle h6 {
  margin-top: 15px;
  text-align: center;
  color: #060606;
}

.socialHeaderCallToAction {
  z-index: 30;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 150px;
}

.socialHeaderGradient {
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(133, 110, 189, 0.05) 30%,
    rgba(165, 131, 243, 0.1) 100%
  );
}

.socialHeader .socialManagement {
  z-index: 20;
  filter: drop-shadow(-36px 36px 12px rgba(255, 255, 255, 0.5));
  margin-left: 10px;
}

.socialHeader .iconBox svg {
  opacity: 0.7;
}

.socialHeader .instaBox {
  animation: InstaBoxAnim 6s ease;
  animation-iteration-count: 1;
  background: linear-gradient(
    130deg,
    rgba(221, 129, 137, 0.5) 0%,
    rgba(252, 197, 215, 1) 100%
  );
  box-shadow: inset 8px -8px 6px rgba(221, 119, 137, 0.6);
  filter: drop-shadow(-12px 12px 24px rgba(221, 119, 137, 1));
}

.socialHeader .youtubeBox {
  animation: youtubeBoxAnim 6s ease;
  animation-iteration-count: 1;
  background: linear-gradient(
    130deg,
    rgba(215, 102, 98, 1) 0%,
    rgba(244, 181, 161, 1) 100%
  );
  box-shadow: inset 8px -8px 6px rgba(215, 82, 98, 0.6);
  filter: drop-shadow(-12px 12px 24px rgba(215, 82, 98, 1));
}

.socialHeader .tiktokBox {
  animation: tiktokFacebookBoxAnim 6s ease;
  animation-iteration-count: 1;
  background: linear-gradient(
    130deg,
    rgba(221, 168, 232, 1) 0%,
    rgba(249, 215, 253, 1) 100%
  );
  box-shadow: inset 8px -8px 6px rgba(221, 168, 232, 0.6);
  filter: drop-shadow(-12px 12px 24px rgba(221, 168, 232, 1));
}

.socialHeader .facebookBox {
  animation: tiktokFacebookBoxAnim 6s ease;
  animation-iteration-count: 1;
  background: linear-gradient(
    130deg,
    rgba(136, 156, 218, 1) 0%,
    rgba(189, 202, 251, 1) 100%
  );
  box-shadow: inset 8px -8px 6px rgba(136, 156, 218, 0.6);
  filter: drop-shadow(-12px 12px 24px rgba(136, 156, 218, 1));
}

/* Services Page */

.socialPostFull {
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 150px;
  border-radius: 0 0 30px 30px;
  z-index: 3;
}

.socialPostFull .socialPost {
  position: absolute;
  top: 0px;
  right: 150px;
}

.socialPostFull .digitalCreations {
  position: absolute;
  top: 0px;
  right: 100px;
}

.socialPostFull .socialManagement {
  position: absolute;
  top: -150px;
  right: -50px;
}

.socialPostFull .serviceName {
  color: #060606;
}

@media only screen and (max-width: 900px) {
  .socialPostFull {
    box-sizing: border-box;
    padding: 0 0 20px 0;
  }

  .socialPostFull .serviceName {
    position: relative;
    left: 5%;
  }

  .socialPostFull .socialPost {
    display: none;
  }
  .socialPostFull .digitalCreations {
    display: none;
  }
  .socialPostFull .socialManagement {
    display: none;
  }
}

.serviceDetailsGrid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px;
}

.serviceDetailsCard {
  width: 100%;
  box-sizing: border-box;
  display: block;
  padding: 30px 20px 30px 20px;
}

.serviceDetailsCard svg {
  padding-left: 15px;
}

.serviceDetailsCardLast {
  border-radius: 30px;
  overflow: hidden;
  background: rgba(189, 202, 251, 0.1);
}

.serviceDetailsCard .svgIcon {
  padding-bottom: 30px;
}

.serviceDetailsCard h3 {
  padding-bottom: 15px;
}

.serviceDetailsCard p {
  padding-bottom: 15px;
}

@media only screen and (max-width: 900px) {
  .serviceDetailsGrid {
    grid-template-columns: 1fr;
    grid-template-rows: repeta(5, 1fr);
  }

  .serviceDetailsCard {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .serviceDetailsCard svg {
    padding-left: 0px;
  }
}
