.unterseiteInfo {
  width: 100%;
  display: grid;
  grid-template-columns: 1.5fr 20px 2.4fr 20px 1.2fr;
  max-height: 300px;
  margin: 50px 0 30px 0;
  box-sizing: border-box;
}

.unterseiteTitle {
  position: relative;
  grid-column: 1/2;
  grid-row: 1/2;
}

.unterseiteText {
  position: relative;
  grid-column: 3/4;
  grid-row: 1/2;
}

.unterseiteButtons {
  position: relative;
  grid-column: 5/6;
  grid-row: 1/2;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
}

.minimalButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 10px 0;
  border-radius: 50px;
}
.minimalButton h4 {
  padding-right: 4px;
  transition: all 0.6s ease;
}

.minimalButton:hover h4 {
  padding-right: 12px;
}

@media only screen and (max-width: 1023px) {
  .unterseiteInfo {
    width: 100%;
    display: grid;
    grid-template-columns: 1.5fr 20px 2.4fr;
  }

  .unterseiteButtons {
    position: relative;
    grid-column: 1/4;
    grid-row: 2/3;
    border-left: 0px;
    padding: 10px 20% 0 20%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
  }
}

@media only screen and (max-width: 450px) {
  .unterseiteInfo {
    display: flex;
    flex-direction: column;
  }

  .unterseiteTitle {
    padding-bottom: 15px;
  }

  .unterseiteText {
    padding-bottom: 15px;
  }

  .unterseiteButtons {
    flex-direction: row;
    padding: 0;
  }
}
