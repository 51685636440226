@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700;800&family=Raleway:wght@300;400&display=swap);
/* Big Title */

.bigTitle {
  width: 100%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 900px) {
  .bigTitle {
    width: auto;
  }
}

/* Video Section Component componet */

.videoSection {
  z-index: 20;
  width: 100%;
  height: 100vh;
  min-height: 600px;
  position: relative;
  overflow: hidden;
  background-color: rgba(206, 208, 237, 1);
  display: flex;
  flex-direction: row;
}

.videoSection video {
  position: absolute;
  z-index: 10;
  background-size: 100% 100%;
  margin-left: 50vw;
  transform: translateX(-50%) translateY(-50%);
  margin-top: 50vh;
  top: 0px;
  left: 0px; /* fixed to left. Replace it by right if you want.*/
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -5;
  opacity: 1;
}

.videoSection .fullScreenImage {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-size: cover;
}

@media only screen and (max-width: 900px) {
  .videoSection .fullScreenImage {
    background-repeat: no-repeat;
    background-position: 30% 0;
  }
}

.videoOverlay {
  width: 100%;
  height: 100%;
  z-index: 15;
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(256, 256, 256, 1) 0%,
    rgba(256, 256, 256, 0.4) 20%,
    rgba(256, 256, 256, 0) 40%,
    rgba(256, 256, 256, 0) 70%,
    rgba(256, 256, 256, 0.8) 85%,
    rgba(256, 256, 256, 1) 100%
  );
}

.projectOverlay {
  width: 100%;
  height: 100%;
  z-index: 15;
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(256, 256, 256, 1) 1%,
    rgba(256, 256, 256, 0.7) 20%,
    rgba(256, 256, 256, 0) 40%,
    rgba(256, 256, 256, 0) 100%
  );
}

.videoSectionLeft {
  width: 70%;
  height: 100%;
  position: relative;
}

.videoSectionRight {
  width: 30%;
  min-width: 300px;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 30px;
}

.seiteInfo {
  z-index: 30;
  width: 300px;
  height: 82%;
  max-height: 1200px;
  right: 30px;
  display: flex;
  flex-direction: column;
  background: rgba(256, 256, 256, 0.3);
  border-radius: 16px;
  padding: 50px 30px 30px 30px;
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
}

.seiteTitle,
.seiteText {
  margin-bottom: 15px;
}

.seiteButtons {
  bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .videoSection {
    flex-direction: column;
  }

  .videoOverlay {
    background: linear-gradient(
      0deg,
      rgba(256, 256, 256, 1) 0%,
      rgba(256, 256, 256, 0) 35%,
      rgba(256, 256, 256, 0.7) 50%,
      rgba(256, 256, 256, 0) 65%,
      rgba(256, 256, 256, 0.8) 90%,
      rgba(256, 256, 256, 1) 100%
    );
  }

  .projectOverlay {
    background: linear-gradient(
      0deg,
      rgba(256, 256, 256, 1) 1%,
      rgba(256, 256, 256, 0.5) 50%,
      rgba(256, 256, 256, 0) 70%,
      rgba(256, 256, 256, 0) 100%
    );
  }

  .videoSectionLeft {
    width: 100%;
    height: 55%;
  }

  .videoSectionRight {
    width: 100%;
    height: 40%;
    padding: 15px;
  }

  .seiteInfo {
    width: 100%;
    height: auto;
    padding: 30px 20px 20px 20px;
  }

  .seiteButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}

/* Tiken module (tesla style stat head-up) */
.tikenModule {
  z-index: 100;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  bottom: 50px;
}

.tiken h2 {
  color: inherit;
  text-align: center;
  margin-bottom: 5px;
}

.tiken h6 {
  color: inherit;
  text-align: center;
}

.tiken {
  max-width: 150px;
  padding: 0 30px;
}

.secondTiken {
  border-right: 1px solid #0e0e0e;
  border-left: 1px solid #0e0e0e;
}

@media only screen and (max-width: 450px) {
  .tikenModule {
    bottom: 30px;
  }

  .tiken {
    max-width: 100px;
    padding: 0 15px;
  }
}

/* Play Button componet */

.playButtonContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playButton {
  width: 100px;
  height: 100px;
  border-radius: 300px;
  border: 1px solid #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Simple  Button componet */

.simpleBtn {
  border-radius: 60px;
  border: 2px solid #060606;
  color: #060606;
  transition: all 0.6s ease;
  cursor: pointer;
  text-align: center;
  background-color: rgba(256, 256, 256, 0);
  margin: 10px 0px 10px 0;
}

.simpleBtn h4 {
  padding: 10px 20px;
  color: inherit;
  text-align: center;
  white-space: nowrap;
}

.simpleDarkBtn {
  border-color: #060606;
  color: #060606;
}

.simpleLightBtn {
  border-color: #f6f6f6;
  color: #f6f6f6;
}

.simpleBtnDark:hover {
  background-color: #060606;
  color: #f6f6f6;
}

.simpleLightBtn:hover {
  background-color: #f6f6f6;
  color: #060606;
}

/*Circle Button componet */

.circleBtn {
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 10px 0px 10px 0;
  white-space: nowrap;
}

.circleBtnIcon {
  margin-right: 10px;
  border-radius: 20px;
  border: 2px solid;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  font-weight: 900;
  color: inherit;
}

.circleBtnDark {
  border-color: #060606;
  color: #060606;
}

.circleBtnLight {
  border-color: #f6f6f6;
  color: #f6f6f6;
}

.circleBtnDark:hover .circleBtnIcon {
  background-color: #060606;
  color: #f6f6f6;
  border-color: #060606;
}

.circleBtnLight:hover .circleBtnIcon {
  background-color: #f6f6f6;
  color: #060606;
  border-color: #f6f6f6;
}

/* FullScreenEffect Carousel component */

.defaultCarousel {
  z-index: 200;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.4s;
  height: 100%;
}

.defaultCarouselItemWrapper {
  position: absolute;
  height: 100%;
  padding: 0px;
  transition: all 0.6s;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.defaultCarouselItem {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.defaultCarouselControl {
  z-index: 200;
  position: absolute;
  width: 150px;
  height: 50%;
  top: 25%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.next {
  right: 0;
}

.previous {
  margin-left: 0;
}

@media only screen and (max-width: 1023px) {
  .defaultCarouselControl {
    display: none;
  }

  .defaultCarouselItemWrapper {
    transition: all 0.2s ease;
  }
}

/* Filter Blur Img Component */

.filterBlurImgContainer {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
}

.filterBlurImg {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 5;
}

.filterBlurImgBottom {
  position: absolute;
  top: 0;
  z-index: 10 !important;
  -webkit-mask-image: -webkit-linear-gradient(
    90deg,
    rgba(256, 256, 256, 1) 20%,
    rgba(256, 256, 256, 0) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  -webkit-filter: blur(3px);
}

.filterDark {
  -webkit-filter: blur(3px) brightness(50%) !important;
}

.filterLight {
  -webkit-filter: blur(3px) brightness(150%) !important;
}

/* Contact Us Form*/

.contactFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 100px 0;
  padding: 50px;
  color: #060606;
  position: relative;
  border-radius: 50px;
  background: rgba(189, 202, 251, 0.1);
  -webkit-backdrop-filter: blur(16px);
          backdrop-filter: blur(16px);
}

.contactFormContainer h3 {
  padding: 5px 0px 10px 0px;
}

.contactFormContainer p {
  max-width: 450px;
}

.contactForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.form-group {
  width: 100%;
  margin-top: 20px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  background-color: rgba(192, 192, 192, 0);
  padding: 6px;
  font-size: 18px;
  border: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-top: 5px;
  outline: 0;
  transition: 0.3s ease border-bottom;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 28px;
  font-family: "Raleway", sans-serif;
  margin: 0;
}

.form-group input:focus,
.form-group textarea:focus {
  border-bottom: 1px solid rgba(189, 202, 251, 1);
}

textarea {
  resize: vertical;
}

.contactForm button {
  margin-top: 20px;
}

::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 28px;
  font-family: "Raleway", sans-serif;
  margin: 0;
}

/* Background svg decorations */

.backSvg {
  position: absolute;
  z-index: 5;
  opacity: 0.2;
}

.backRightBottom {
  bottom: -20%;
  right: -5%;
  transform: rotate(30deg);
}

.backLeftBottom {
  bottom: -20%;
  left: 5%;
  transform: rotate(-15deg);
}

.backRightTop {
  top: -10%;
  right: 10%;
  transform: rotate(-25deg);
}

.servicesContainer {
  width: 100%;
  height: 500px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  background-color: #f6f6f6;
  border-radius: 30px;
  overflow: hidden;
}

.socialPostContainer {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  padding-bottom: 66px;
  transition: all 0.4s ease;
  overflow: hidden;
}

.servicePage .socialPostContainer {
  border-radius: 30px;
  height: 250px;
  margin-top: 100px;
}

.socialPostContainer:hover .circleBtnIcon {
  background-color: rgba(256, 256, 256, 0.3);
  color: #f6f6f6;
  border-color: #f6f6f6;
}

@media only screen and (min-width: 451px) {
  .socialPostContainer:hover > .socialPost {
    -webkit-filter: drop-shadow(-60px 60px 60px rgb(0, 0, 0, 0.11));
            filter: drop-shadow(-60px 60px 60px rgb(0, 0, 0, 0.11));
    transform: scale(1.2) rotateX(60deg) rotateY(0deg) rotateZ(-45deg)
      translateZ(50px);
  }
}

.serviceName {
  position: absolute;
  z-index: 30;
  top: 50px;
  left: 50px;
  width: 100px;
  color: #fff;
}

.serviceName h5 {
  white-space: nowrap;
}

.plusButton {
  position: absolute;
  bottom: 50px;
  left: 50px;
}

.transparentBlueGradientBack {
  background: rgba(189, 202, 251, 0.1);
}

.redGradientBack {
  background: linear-gradient(
    130deg,
    rgba(215, 102, 98, 1) 0%,
    rgba(244, 181, 161, 0.7) 100%
  );
}

.pinkGradientBack {
  background: linear-gradient(
    130deg,
    rgba(221, 129, 137, 1) 0%,
    rgba(252, 197, 215, 0.7) 100%
  );
}

.violetGradientBack {
  background: linear-gradient(
    130deg,
    rgba(221, 168, 232, 1) 0%,
    rgba(249, 215, 253, 0.7) 100%
  );
}

.socialPost1 {
  grid-column: 1/2;
  grid-row: 1/3;
  z-index: 3;
}
.socialPost2 {
  grid-column: 2/3;
  grid-row: 1/2;

  z-index: 2;
}

.socialPost3 {
  grid-column: 2/3;
  grid-row: 2/3;
  z-index: 1;
}

.socialPost1 .socialPost {
  position: absolute;
  bottom: 0px;
  right: 50px;
}

.socialPost2 .digitalCreations {
  position: absolute;
  bottom: -30px;
  right: -30px;
}

.socialPost3 .socialManagement {
  position: absolute;
  bottom: -250px;
  right: -150px;
}

.socialPost {
  width: 200px;
  height: 280px;
  background-color: #fff;
  border-radius: 30px;
  overflow: hidden;
  position: absolute;
  animation: socialPostAnimation 6s ease-in;
  animation-iteration-count: 9;
  transition: all 0.3s ease;
  transform: scale(1.2) rotateX(60deg) rotateY(0deg) rotateZ(-45deg)
    translateZ(0px);
}

@media only screen and (max-width: 900px) and (min-height: 600px),
  (orientation: portrait) {
  .servicesContainer {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    min-height: 600px;
  }

  .serviceName {
    position: absolute;
    z-index: 30;
    top: 25px;
    left: 25px;
    width: 100px;
    color: #fff;
  }

  .plusButton {
    bottom: 25px;
    left: 25px;
  }

  .socialPost {
    transform: scale(0.8) rotateX(60deg) rotateY(0deg) rotateZ(-45deg)
      translateZ(0px) !important;
  }

  .socialPost1 {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .socialPost2 {
    grid-column: 1/2;
    grid-row: 2/3;
  }

  .socialPost3 {
    grid-column: 1/2;
    grid-row: 3/4;
  }

  .socialPost1 .socialPost {
    bottom: -50px;
    right: 0px;
  }
}

@keyframes socialPostAnimation {
  0% {
    width: 200px;
    height: 280px;
  }
  27% {
    width: 200px;
    height: 280px;
  }
  30% {
    width: 200px;
    height: 340px;
  }
  31% {
    width: 200px;
    height: 310px;
  }
  33% {
    width: 200px;
    height: 320px;
  }
  60% {
    width: 200px;
    height: 320px;
  }
  63% {
    width: 200px;
    height: 160px;
  }
  64% {
    width: 200px;
    height: 190px;
  }
  66% {
    width: 200px;
    height: 180px;
  }
  93% {
    width: 200px;
    height: 180px;
  }
  97% {
    width: 200px;
    height: 300px;
  }
  98% {
    width: 200px;
    height: 270px;
  }
  100% {
    width: 200px;
    height: 280px;
  }
}

.socialPost3D {
  z-index: 30;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  box-shadow: inset 4px -4px 4px rgba(215, 82, 98, 0.6);
  -webkit-filter: drop-shadow(-12px 12px 24px rgba(215, 102, 98, 1));
          filter: drop-shadow(-12px 12px 24px rgba(215, 102, 98, 1));
}

.socialProfile {
  z-index: 10;
  width: 30px;
  height: 30px;
  background: linear-gradient(
    130deg,
    rgba(215, 102, 98, 1) 0%,
    rgba(244, 181, 161, 1) 100%
  );
  border-radius: 30px;
  overflow: hidden;
  position: absolute;
  top: 15px;
  left: 15px;
  animation: socialProfileAnimation 6s ease-in;
  animation-iteration-count: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes socialProfileAnimation {
  0% {
    top: 15px;
    left: 15px;
  }
  27% {
    top: 15px;
    left: 15px;
    width: 30px;
  }
  30% {
    top: 100px;
    left: 165px;
    width: 25px;
  }
  31% {
    top: 85px;
    left: 150px;
    width: 32px;
  }
  33% {
    top: 90px;
    left: 155px;
    width: 30px;
  }
  60% {
    top: 90px;
    left: 155px;
    width: 30px;
  }
  63% {
    top: 145px;
    left: 10px;
    width: 25px;
  }
  64% {
    top: 130px;
    left: 20px;
    width: 32px;
  }
  66% {
    top: 135px;
    left: 15px;
    width: 30px;
  }
  93% {
    top: 135px;
    left: 15px;
    height: 30px;
  }
  97% {
    top: 10px;
    left: 15px;
    height: 25px;
  }
  98% {
    top: 20px;
    left: 15px;
    height: 32px;
  }
  100% {
    top: 15px;
    left: 15px;
    height: 30px;
  }
}

.socialImageContainer {
  width: 180px;
  height: 180px;
  background: linear-gradient(
    130deg,
    rgba(215, 102, 98, 1) 0%,
    rgba(244, 181, 161, 1) 100%
  );
  border-radius: 30px;
  position: absolute;
  top: 50px;
  left: 10px;
  animation: socialImageAnimation 6s ease-in;
  animation-iteration-count: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes socialImageAnimation {
  0% {
    top: 50px;
    left: 10px;
    width: 180px;
    height: 180px;
  }
  27% {
    top: 50px;
    left: 10px;
    width: 180px;
    height: 180px;
  }
  30% {
    top: 0px;
    left: 0px;
    width: 200px;
    height: 320px;
  }
  31% {
    top: 0px;
    left: 0px;
    width: 200px;
    height: 320px;
  }
  33% {
    top: 0px;
    left: 0px;
    width: 200px;
    height: 320px;
  }
  60% {
    top: 0px;
    left: 0px;
    width: 200px;
    height: 320px;
  }
  63% {
    top: 15px;
    left: 10px;
    width: 180px;
    height: 110px;
  }
  64% {
    top: 15px;
    left: 10px;
    width: 180px;
    height: 110px;
  }
  66% {
    top: 15px;
    left: 10px;
    width: 180px;
    height: 110px;
  }
  93% {
    top: 15px;
    left: 10px;
    width: 180px;
    height: 110px;
  }
  97% {
    top: 50px;
    left: 10px;
    width: 180px;
    height: 180px;
  }
  98% {
    top: 50px;
    left: 10px;
    width: 180px;
    height: 180px;
  }
  100% {
    top: 50px;
    left: 10px;
    width: 180px;
    height: 180px;
  }
}

.socialAction {
  z-index: 20;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 30px;
  background: linear-gradient(
    130deg,
    rgba(215, 102, 98, 1) 0%,
    rgba(244, 181, 161, 1) 100%
  );
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialLike {
  top: 240px;
  left: 15px;
  animation: socialLikeAnimation 6s ease-in;
  animation-iteration-count: 9;
}

.socialComment {
  top: 240px;
  left: 40px;
  animation: socialCommentAnimation 6s ease-in;
  animation-iteration-count: 9;
}

.socialShare {
  top: 240px;
  left: 65px;
  animation: socialShareAnimation 6s ease-in;
  animation-iteration-count: 9;
}

@keyframes socialLikeAnimation {
  0% {
    top: 240px;
    left: 15px;
  }
  27% {
    top: 240px;
    left: 15px;
  }
  33% {
    top: 130px;
    left: 160px;
  }
  60% {
    top: 130px;
    left: 160px;
  }
  66% {
    top: 140px;
    left: 110px;
  }
  93% {
    top: 140px;
    left: 110px;
  }
  96% {
    top: 220px;
    left: 10px;
  }
  100% {
    top: 240px;
    left: 15px;
  }
}

@keyframes socialCommentAnimation {
  0% {
    top: 240px;
    left: 40px;
  }
  27% {
    top: 240px;
    left: 40px;
  }
  33% {
    top: 155px;
    left: 160px;
  }
  60% {
    top: 155px;
    left: 160px;
  }
  66% {
    top: 140px;
    left: 135px;
  }
  93% {
    top: 140px;
    left: 135px;
  }
  96% {
    top: 220px;
    left: 30px;
  }
  100% {
    top: 240px;
    left: 40px;
  }
}

@keyframes socialShareAnimation {
  0% {
    top: 240px;
    left: 65px;
  }
  27% {
    top: 240px;
    left: 65px;
  }
  33% {
    top: 180px;
    left: 160px;
  }
  60% {
    top: 180px;
    left: 160px;
  }
  66% {
    top: 140px;
    left: 160px;
  }
  93% {
    top: 140px;
    left: 160px;
  }
  96% {
    top: 220px;
    left: 55px;
  }
  100% {
    top: 240px;
    left: 65px;
  }
}

/* Digital Creations style and aniamtions */

.digitalCreations {
  width: 320px;
  margin-top: 50px;
  position: relative;
  transition: all 0.3s ease;
  transform: rotateX(60deg) rotateY(0deg) rotateZ(-45deg) translateZ(0px);
  -webkit-filter: drop-shadow(-12px 12px 15px rgb(221, 129, 137, 1));
          filter: drop-shadow(-12px 12px 15px rgb(221, 129, 137, 1));
  display: flex;
}

.socialPostContainer:hover > .digitalCreations {
  -webkit-filter: drop-shadow(-60px 60px 30px rgb(0, 0, 0, 0.15));
          filter: drop-shadow(-60px 60px 30px rgb(0, 0, 0, 0.15));
  transform: rotateX(60deg) rotateY(0deg) rotateZ(-45deg) translateZ(50px);
}

.digitalPc {
  position: relative;
  width: 260px;
  height: 180px;
  background: linear-gradient(
    130deg,
    rgba(221, 129, 137, 0.5) 0%,
    rgba(252, 197, 215, 1) 100%
  );
  border-radius: 30px;
  box-shadow: inset 4px -4px 4px rgb(221, 129, 137, 1);
  overflow: hidden;
  animation: digitalPcAnim 6s ease-in;
  animation-iteration-count: 9;
}

@keyframes digitalPcAnim {
  0% {
    width: 260px;
    height: 180px;
    margin-left: 40px;
  }
  27% {
    width: 260px;
    height: 180px;
    margin-left: 40px;
  }
  33% {
    width: 260px;
    height: 180px;
    margin-left: 40px;
  }
  60% {
    width: 260px;
    height: 180px;
    margin-left: 40px;
  }
  62% {
    width: 170px;
    height: 180px;
    margin-left: -10px;
  }
  64% {
    width: 200px;
    height: 180px;
    margin-left: 5px;
  }
  66% {
    width: 190px;
    height: 180px;
    margin-left: 0px;
  }
  93% {
    width: 190px;
    height: 180px;
    margin-left: 0px;
  }
  95% {
    width: 280px;
    height: 180px;
    margin-left: 50px;
  }
  97% {
    width: 250px;
    height: 180px;
    margin-left: 30px;
  }
  100% {
    width: 260px;
    height: 180px;
    margin-left: 40px;
  }
}

.digitalPhone {
  position: relative;
  width: 0px;
  height: 180px;
  background: linear-gradient(
    130deg,
    rgba(221, 129, 137, 0.5) 0%,
    rgba(252, 197, 215, 1) 100%
  );
  border-radius: 30px;
  box-shadow: inset 4px -4px 4px rgb(252, 197, 215, 1);
  overflow: hidden;
  animation: digitalPhoneAnim 6s ease-in;
  animation-iteration-count: 9;
  margin-left: 20px;
}

@keyframes digitalPhoneAnim {
  0% {
    width: 0px;
    height: 180px;
  }
  27% {
    width: 0px;
    height: 180px;
  }
  33% {
    width: 0px;
    height: 180px;
  }
  60% {
    width: 0px;
    height: 180px;
  }
  62% {
    width: 130px;
    height: 180px;
  }
  64% {
    width: 100px;
    height: 180px;
  }
  66% {
    width: 110px;
    height: 180px;
  }
  93% {
    width: 110px;
    height: 180px;
  }
  95% {
    width: 0px;
    height: 180px;
  }
  97% {
    width: 20px;
    height: 180px;
  }
  100% {
    width: 0px;
    height: 180px;
  }
}

.phoneBox {
  width: 90px;
  margin-left: 10px;
  margin-top: 10px;
  height: 160px;
  background: #fff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBox {
  width: 100px;
  height: 25px;
  margin-left: 90px;
  margin-top: 70px;
  background: #fff;
  border-radius: 30px;
  position: reative;
  animation: searchBoxAnim 6s ease-in;
  animation-iteration-count: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes searchBoxAnim {
  0% {
    width: 100px;
    height: 25px;
    margin-left: 90px;
    margin-top: 70px;
  }
  27% {
    width: 100px;
    height: 25px;
    margin-left: 90px;
    margin-top: 70px;
  }
  33% {
    width: 220px;
    height: 25px;
    margin-left: 20px;
    margin-top: 10px;
  }
  60% {
    width: 220px;
    height: 25px;
    margin-left: 20px;
  }
  66% {
    width: 160px;
    height: 25px;
    margin-left: 20px;
    margin-top: 10px;
  }
  93% {
    width: 160px;
    height: 25px;
    margin-left: 20px;
    margin-top: 10px;
  }
  100% {
    width: 100px;
    height: 25px;
    margin-left: 90px;
    margin-top: 70px;
  }
}

.webBox {
  width: 240px;
  height: 120px;
  margin-left: 10px;
  margin-top: 10px;
  background: #fff;
  border-radius: 30px;
  margin-left: 10px;
  margin-top: 10px;
  animation: webBoxAnim 6s ease-in;
  animation-iteration-count: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes webBoxAnim {
  0% {
    width: 0px;
    height: 25px;
    margin-left: 120px;
    margin-top: 100px;
  }
  27% {
    width: 0px;
    height: 25px;
    margin-left: 120px;
    margin-top: 100px;
  }
  33% {
    width: 220px;
    height: 120px;
    margin-left: 20px;
    margin-top: 10px;
  }
  60% {
    width: 220px;
    height: 120px;
    margin-left: 20px;
    margin-top: 10px;
  }
  66% {
    width: 160px;
    height: 120px;
    margin-left: 20px;
    margin-top: 10px;
  }
  93% {
    width: 160px;
    height: 120px;
    margin-left: 20px;
    margin-top: 10px;
  }
  100% {
    width: 0px;
    height: 0px;
    margin-left: 120px;
    margin-top: 100px;
  }
}

/* Social media management styles and animations */

.socialManagement {
  position: relative;
  width: 600px;
  height: 600px;
  position: relative;
  transition: all 0.3s ease;
  transform: rotateX(60deg) rotateY(0deg) rotateZ(-45deg) translateZ(0px);
  -webkit-filter: drop-shadow(-12px 12px 15px rgb(221, 168, 232, 1));
          filter: drop-shadow(-12px 12px 15px rgb(221, 168, 232, 1));
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialPostContainer:hover > .socialManagement {
  -webkit-filter: drop-shadow(-60px 60px 30px rgb(221, 168, 232, 1));
          filter: drop-shadow(-60px 60px 30px rgb(221, 168, 232, 1));
  transform: rotateX(60deg) rotateY(0deg) rotateZ(-45deg) translateZ(50px);
}

.socialManagementContent {
  position: relative;
  width: 320px;
  height: 320px;
  display: flex;
}

.iconBox {
  position: absolute;
  width: 140px;
  height: 140px;
  background: grey;
  box-shadow: inset 4px -4px 4px rgba(221, 168, 232, 1);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.instaBox {
  z-index: 100;
  left: 160px;
  animation: InstaBoxAnim 6s ease;
  animation-iteration-count: 9;
  background: linear-gradient(
    130deg,
    rgba(221, 168, 232, 1) 0%,
    rgba(249, 215, 253, 1) 100%
  );
}

.youtubeBox {
  top: 160px;
  animation: youtubeBoxAnim 6s ease;
  animation-iteration-count: 9;
  background: linear-gradient(
    130deg,
    rgba(221, 168, 232, 1) 0%,
    rgba(249, 215, 253, 1) 100%
  );
}

.tiktokBox {
  animation: tiktokFacebookBoxAnim 6s ease;
  animation-iteration-count: 9;
  background: linear-gradient(
    130deg,
    rgba(221, 168, 232, 1) 0%,
    rgba(249, 215, 253, 1) 100%
  );
}

.facebookBox {
  left: 160px;
  top: 160px;
  animation: tiktokFacebookBoxAnim 6s ease;
  animation-iteration-count: 9;
  background: linear-gradient(
    130deg,
    rgba(221, 168, 232, 1) 0%,
    rgba(249, 215, 253, 1) 100%
  );
}

@keyframes InstaBoxAnim {
  0% {
    left: 0px;
    width: 320px;
    height: 320px;
  }
  27% {
    left: 0px;
    width: 320px;
    height: 320px;
  }
  30% {
    left: 180px;
    width: 120px;
    height: 120px;
  }
  31% {
    left: 150px;
    width: 150px;
    height: 150px;
  }
  33% {
    left: 160px;
    width: 140px;
    height: 140px;
  }
  60% {
    left: 160px;
    width: 140px;
    height: 140px;
  }
  63% {
    left: 160px;
    width: 140px;
    height: 140px;
  }
  93% {
    left: 160px;
    width: 140px;
    height: 140px;
  }
  97% {
    left: -20px;
    width: 340px;
    height: 340px;
  }
  98% {
    left: 10px;
    width: 310px;
    height: 310px;
  }
  100% {
    left: 0px;
    width: 320px;
    height: 320px;
  }
}

@keyframes youtubeBoxAnim {
  0% {
    transform: scale(0);
  }
  27% {
    transform: scale(0);
  }
  30% {
    transform: scale(1.2);
  }
  31% {
    transform: scale(0.9);
  }
  33% {
    transform: scale(1);
  }
  93% {
    transform: scale(1);
  }
  97% {
    transform: scale(0.1);
  }
  98% {
    transform: scale(0.3);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes tiktokFacebookBoxAnim {
  0% {
    transform: scale(0);
  }
  27% {
    transform: scale(0);
  }
  30% {
    transform: scale(0);
  }
  31% {
    transform: scale(0);
  }
  33% {
    transform: scale(0);
  }
  60% {
    transform: scale(0);
  }
  63% {
    transform: scale(1.2);
  }
  64% {
    transform: scale(0.9);
  }
  66% {
    transform: scale(1);
  }
  93% {
    transform: scale(1);
  }
  97% {
    transform: scale(0.1);
  }
  98% {
    transform: scale(0.3);
  }
  100% {
    transform: scale(0);
  }
}

/* Social Header Variation */

.socialHeader {
  width: 100%;
  height: 95vh;
  min-height: 600px;
  background: radial-gradient(
    circle,
    rgba(206, 208, 237, 0.1) 0%,
    rgba(206, 208, 237, 0.3) 30%,
    rgba(255, 255, 255, 1) 100%
  );
  margin-bottom: 50px;
}

.socialHeaderTitle {
  z-index: 30;
  position: absolute;
  top: 100px;
}

@media only screen and (max-width: 450px) {
  .socialHeaderTitle {
    max-width: 300px;
  }

  .socialHeader {
    height: 90vh;
  }

  .socialHeaderCallToAction {
    bottom: 120px !important;
  }
}

.socialHeaderTitle h1 {
  text-align: center;
  color: #060606;
}

.socialHeaderTitle h6 {
  margin-top: 15px;
  text-align: center;
  color: #060606;
}

.socialHeaderCallToAction {
  z-index: 30;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 150px;
}

.socialHeaderGradient {
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(133, 110, 189, 0.05) 30%,
    rgba(165, 131, 243, 0.1) 100%
  );
}

.socialHeader .socialManagement {
  z-index: 20;
  -webkit-filter: drop-shadow(-36px 36px 12px rgba(255, 255, 255, 0.5));
          filter: drop-shadow(-36px 36px 12px rgba(255, 255, 255, 0.5));
  margin-left: 10px;
}

.socialHeader .iconBox svg {
  opacity: 0.7;
}

.socialHeader .instaBox {
  animation: InstaBoxAnim 6s ease;
  animation-iteration-count: 1;
  background: linear-gradient(
    130deg,
    rgba(221, 129, 137, 0.5) 0%,
    rgba(252, 197, 215, 1) 100%
  );
  box-shadow: inset 8px -8px 6px rgba(221, 119, 137, 0.6);
  -webkit-filter: drop-shadow(-12px 12px 24px rgba(221, 119, 137, 1));
          filter: drop-shadow(-12px 12px 24px rgba(221, 119, 137, 1));
}

.socialHeader .youtubeBox {
  animation: youtubeBoxAnim 6s ease;
  animation-iteration-count: 1;
  background: linear-gradient(
    130deg,
    rgba(215, 102, 98, 1) 0%,
    rgba(244, 181, 161, 1) 100%
  );
  box-shadow: inset 8px -8px 6px rgba(215, 82, 98, 0.6);
  -webkit-filter: drop-shadow(-12px 12px 24px rgba(215, 82, 98, 1));
          filter: drop-shadow(-12px 12px 24px rgba(215, 82, 98, 1));
}

.socialHeader .tiktokBox {
  animation: tiktokFacebookBoxAnim 6s ease;
  animation-iteration-count: 1;
  background: linear-gradient(
    130deg,
    rgba(221, 168, 232, 1) 0%,
    rgba(249, 215, 253, 1) 100%
  );
  box-shadow: inset 8px -8px 6px rgba(221, 168, 232, 0.6);
  -webkit-filter: drop-shadow(-12px 12px 24px rgba(221, 168, 232, 1));
          filter: drop-shadow(-12px 12px 24px rgba(221, 168, 232, 1));
}

.socialHeader .facebookBox {
  animation: tiktokFacebookBoxAnim 6s ease;
  animation-iteration-count: 1;
  background: linear-gradient(
    130deg,
    rgba(136, 156, 218, 1) 0%,
    rgba(189, 202, 251, 1) 100%
  );
  box-shadow: inset 8px -8px 6px rgba(136, 156, 218, 0.6);
  -webkit-filter: drop-shadow(-12px 12px 24px rgba(136, 156, 218, 1));
          filter: drop-shadow(-12px 12px 24px rgba(136, 156, 218, 1));
}

/* Services Page */

.socialPostFull {
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 150px;
  border-radius: 0 0 30px 30px;
  z-index: 3;
}

.socialPostFull .socialPost {
  position: absolute;
  top: 0px;
  right: 150px;
}

.socialPostFull .digitalCreations {
  position: absolute;
  top: 0px;
  right: 100px;
}

.socialPostFull .socialManagement {
  position: absolute;
  top: -150px;
  right: -50px;
}

.socialPostFull .serviceName {
  color: #060606;
}

@media only screen and (max-width: 900px) {
  .socialPostFull {
    box-sizing: border-box;
    padding: 0 0 20px 0;
  }

  .socialPostFull .serviceName {
    position: relative;
    left: 5%;
  }

  .socialPostFull .socialPost {
    display: none;
  }
  .socialPostFull .digitalCreations {
    display: none;
  }
  .socialPostFull .socialManagement {
    display: none;
  }
}

.serviceDetailsGrid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px;
}

.serviceDetailsCard {
  width: 100%;
  box-sizing: border-box;
  display: block;
  padding: 30px 20px 30px 20px;
}

.serviceDetailsCard svg {
  padding-left: 15px;
}

.serviceDetailsCardLast {
  border-radius: 30px;
  overflow: hidden;
  background: rgba(189, 202, 251, 0.1);
}

.serviceDetailsCard .svgIcon {
  padding-bottom: 30px;
}

.serviceDetailsCard h3 {
  padding-bottom: 15px;
}

.serviceDetailsCard p {
  padding-bottom: 15px;
}

@media only screen and (max-width: 900px) {
  .serviceDetailsGrid {
    grid-template-columns: 1fr;
    grid-template-rows: repeta(5, 1fr);
  }

  .serviceDetailsCard {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .serviceDetailsCard svg {
    padding-left: 0px;
  }
}

.vierCardModule {
  position: relative;
  width: 100%;
  height: 450px;
  box-sizing: border-box;
  margin: 0px;
}

.vierCardContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 900px) and (min-width: 451px) {
  .vierCardModule {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100vw;
  }

  .vierCardContent {
    width: 160% !important;
    padding: 2%;
  }

  .vierCard {
    width: 25% !important;
    margin: 0 1%;
  }
}

@media only screen and (max-width: 450px) and (min-height: 600px) {
  .vierCardModule {
    width: 100vw;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .vierCardContent {
    width: 300%;
    padding: 0 2%;
  }

  .vierCard {
    width: 25%;
    margin: 0 1%;
  }
}

.vierCard {
  position: relative;
  width: 23%;
  height: 100%;
  overflow: hidden;
  border-radius: 30px;
  box-sizing: border-box;
  transition: all 0.6s ease;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.vierCard:hover {
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transform: scale(1.02);
}

/*.vierCard:hover .backImg {
  filter: blur(3px) brightness(100%);
}*/

.vierCard .backImg {
  position: absolute;
  height: 112%;
  top: -6%;
  z-index: 5;
  transition: all 0.6s ease;
}

.filterBlur {
  position: absolute;
  z-index: 10 !important;
  position: absolute;
  -webkit-mask-image: -webkit-linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 20%,
    rgba(188, 188, 188, 0) 40%,
    rgba(0, 0, 0, 0) 100%
  );
  -webkit-filter: blur(3px) brightness(200%) !important;
}

.projectInfo {
  z-index: 20;
  width: 100%;
  position: absolute;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vierCard .projectLogo {
  position: absolute;
  bottom: 25px;
  z-index: 20;
  width: 80%;
  left: 10%;
}

.vierCardLast {
  border: 2px solid rgba(206, 208, 237, 1);
  box-shadow: none !important;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgb(235, 230, 248, 1) 90%
  );
}

.vierCardLast .filterBlurImg {
  -webkit-filter: blur(3px) brightness(100%) saturate(0) !important;
  opacity: 0.2;
}

.vierCardLast:hover {
  border: 3px solid rgba(206, 208, 237, 1);
}

.vierCardLast h3 {
  z-index: 20;
  position: absolute;
  bottom: 50%;
  left: 20px;
}

.vierCircleButton {
  position: absolute;
  z-index: 50;
  top: 50%;
  left: 20px;
  transition: all 0.6s ease;
}

.vierCard:hover .vierCircleButton {
  left: 40%;
}

.vierCard:hover .circleBtnIcon {
  background-color: #060606;
  color: #f6f6f6;
  border-color: #060606;
}

/* vierCards on projects page / case history */

.projectsCards {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin: 0px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.projectsCards .vierCard {
  width: 100% !important;
  height: 400px;
}

.projectTitle {
  color: #fff;
}

@media only screen and (max-width: 900px) and (min-width: 451px) {
  .projectsCards {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 450px) {
  .projectsCards {
    grid-template-columns: 1fr;
  }
  .projectsCards .vierCard {
    height: 250px !important;
  }
}

.blurPage {
  -webkit-filter: blur(0px);
          filter: blur(0px);
  transition: all 0.3s ease;
}

.blurPageActive {
  -webkit-filter: blur(15px);
          filter: blur(15px);
}

.mainPage {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background-color: #ffffff;
  overflow-x: hidden;
  overflow-y: scroll;
}

.maxWidth {
  max-width: 1200px;
  margin: 0 auto;
}

.homepage {
  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.contactPage {
  min-height: 600px;
}

.projectPage .tiken {
  color: #060606;
  border-color: #060606;
}

/* Full section component and dritte addon (tesla style third view)*/

.fullSection {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  box-sizing: border-box;
  margin-bottom: 100px;
}

.fullHeight {
  height: 100vh;
}

.fullSectionContent {
  z-index: 20;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.limitWidthContent {
  max-width: 1200px;
  padding: 0 5%;
}

@media only screen and (max-width: 900px) {
  .limitWidthContent {
    padding: 0 5%;
  }
}

.dritteTop {
  height: 300px;
}

.dritteBottom {
  min-height: 250px;
}

@media only screen and (min-height: 600px) {
  .dritteSection {
    height: 100vh;
  }

  .dritteTop {
    height: 100% !important;
  }

  .dritteBottom {
    height: 25%;
  }
}

@media only screen and (min-height: 600px) and (max-width: 450px) {
  .dritteSection {
    height: 100vh;
  }

  .dritteTop {
    height: 50% !important;
  }

  .dritteBottom {
    height: 50%;
    min-height: 300px;
  }
}

/* Social Box */

.socialBox {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.socialItem {
  width: 50px;
  height: 50px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s ease;
  cursor: pointer;
  border-radius: 30px;
}

.socialItem:hover {
  background: rgba(189, 202, 251, 1);
}

/* Footer Component */

.footer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 2% 50px 2%;
  box-sizing: border-box;
  background: linear-gradient(
    0deg,
    rgba(165, 131, 243, 0.1) 0%,
    rgba(133, 110, 189, 0.05) 70%,
    rgba(255, 255, 255, 1) 100%
  );
  min-height: 300px;
}

.footerContent {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  max-width: 1200px;
}

.footerFirstRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column: 1/2;
  grid-row: 1/2;
}

.footerMainMenu {
  grid-column: 1/2;
  grid-row: 2/3;
  display: flex;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.footerMainMenu h4 {
  margin: 5px 10px;
  cursor: pointer;
}

.footerEnd {
  grid-column: 1/2;
  grid-row: 3/4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footerEndMenu {
  display: flex;
}

.footerEndMenu h6 {
  margin: 5px 10px;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .footerFirstRow {
    flex-direction: column;
  }

  .footerEnd {
    width: 100%;
    justify-content: center;
  }
}

/* Service Page Componeng */

.servicePage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.servicePage .socialPostContainer {
  overflow: visible;
}

.servicesPage {
  padding-top: 100px;
}

/* Case History / Projects page */

.caseHistoryPage {
  padding-top: 100px;
}

/* Milanote */

.milanoteContainer {
  margin-top: 80px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.header-right {
  opacity: 0;
}

.unterseiteInfo {
  width: 100%;
  display: grid;
  grid-template-columns: 1.5fr 20px 2.4fr 20px 1.2fr;
  max-height: 300px;
  margin: 50px 0 30px 0;
  box-sizing: border-box;
}

.unterseiteTitle {
  position: relative;
  grid-column: 1/2;
  grid-row: 1/2;
}

.unterseiteText {
  position: relative;
  grid-column: 3/4;
  grid-row: 1/2;
}

.unterseiteButtons {
  position: relative;
  grid-column: 5/6;
  grid-row: 1/2;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
}

.minimalButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 10px 0;
  border-radius: 50px;
}
.minimalButton h4 {
  padding-right: 4px;
  transition: all 0.6s ease;
}

.minimalButton:hover h4 {
  padding-right: 12px;
}

@media only screen and (max-width: 1023px) {
  .unterseiteInfo {
    width: 100%;
    display: grid;
    grid-template-columns: 1.5fr 20px 2.4fr;
  }

  .unterseiteButtons {
    position: relative;
    grid-column: 1/4;
    grid-row: 2/3;
    border-left: 0px;
    padding: 10px 20% 0 20%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
  }
}

@media only screen and (max-width: 450px) {
  .unterseiteInfo {
    display: flex;
    flex-direction: column;
  }

  .unterseiteTitle {
    padding-bottom: 15px;
  }

  .unterseiteText {
    padding-bottom: 15px;
  }

  .unterseiteButtons {
    flex-direction: row;
    padding: 0;
  }
}

.headBarContainer {
  position: absolute;
  z-index: 2000;
  width: 100%;
  height: 50px;
  overflow: visible;
  margin: 0;
  padding: 0px 0 0 0;
  box-sizing: border-box;
  transition: all 0.3s ease;
  background-color: rgba(256, 256, 256, 1);
}

.headBarContainerActive {
  height: 100vh;
  background-color: rgba(256, 256, 256, 1);
}

.headBarContainerTop {
  height: 80px !important;
  background-color: rgba(256, 256, 256, 0) !important;
  padding: 25px 0 0 0 !important;
}

.headBar {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  padding: 10px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: all 0.3s ease;
  margin: 0 auto;
}

.headBarButton {
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
}

.headBarButtonContent {
  transition: all 0.6s ease;
}

.headBarContainerActive .headBarButtonContent {
  transform: rotate(45deg) scale(1.2);
}

.headBarMenu {
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px;
  pointer-events: none;
  display: block;
  margin: 60px 0 0 0;
  transition: all 0.3s ease;
  height: 0;
  opacity: 0;
  color: rgba(0, 0, 0, 1);
}

.headBarContainerActive .headBarMenu {
  pointer-events: auto;
  margin: 30px 0;
  opacity: 1;
  height: auto;
}

.headBarMenuItem {
  text-align: center;
  margin-bottom: 15px;
  cursor: pointer;
}

.headBarMenuItem:hover {
  text-align: center;
  margin-bottom: 15px;
  cursor: pointer;
}

html,
body,
.App {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Open Sans", sans-serif;
  background-color: #0e0e0e;
  color: #0e0e0e;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  font-size: 62.5%;
}

label {
  font-size: 100%;
}

a {
  color: inherit !important;
}

button {
  padding: 0;
  margin: 0;
  outline: none !important;
}

@media only screen and (min-width: 900px) {
  .onlyTablet {
    display: none !important;
  }
}

@media only screen and (max-width: 900px) {
  .noShowTablet {
    display: none !important;
  }
}

::selection {
  background: rgba(255, 255, 255, 0);
}

div,
img,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: inherit;
}

h1 {
  font-size: 4.8rem;
  font-weight: 700;
  line-height: 1;
}

h2 {
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.2rem;
}

h3 {
  font-weight: 700;
  font-size: 2.2rem;
}

h4 {
  font-weight: 500;
  font-size: 1.44rem;
}

h5 {
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 3.2rem;
  font-family: "Raleway", sans-serif;
}

p {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 28px;
  font-family: "Raleway", sans-serif;
  margin: 0;
}

h6 {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-family: "Raleway", sans-serif;
  margin: 0;
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

span {
  margin: 0;
  padding: 0;
  transition: all ease 0.39s;
}

a {
  color: #3b4255;
  text-decoration: none;
}

::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 1023px) {
  h1 {
    font-size: 4rem;
    font-weight: 700;
    line-height: 1;
  }

  h2 {
    font-weight: 700;
    font-size: 2.6rem;
    line-height: 3.2rem;
  }

  h3 {
    font-weight: 700;
    font-size: 1.7rem;
  }

  h4 {
    font-weight: 500;
    font-size: 1.2rem;
  }

  h5 {
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  p {
    font-weight: 400;
    font-size: 1.44rem;
    line-height: 18px;
    font-family: "Raleway", sans-serif;
  }

  h6 {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4rem;
    font-family: "Raleway", sans-serif;
    margin: 0;
  }
}

/* Animations */

.animBottomUp {
  opacity: 0;
  margin-top: 0%;
  animation: bottomUp 0.3s ease forwards;
}

@keyframes bottomUp {
  from {
    opacity: 0;
    margin-top: 60px;
  }
  to {
    opacity: 1;
    margin-top: 0px;
  }
}

.animOpacity {
  opacity: 0;
  animation: animOpacity 0.3s ease forwards;
}

@keyframes animOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Animation Delays*/

.delay1 {
  animation-delay: 0.1s;
}

.delay2 {
  animation-delay: 0.2s;
}

.delay3 {
  animation-delay: 0.3s;
}

.delay4 {
  animation-delay: 0.4s;
}

.delay5 {
  animation-delay: 0.5s;
}

.delay6 {
  animation-delay: 0.6s;
}

.noOverflow {
  overflow: hidden;
}

