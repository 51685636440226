.blurPage {
  filter: blur(0px);
  transition: all 0.3s ease;
}

.blurPageActive {
  filter: blur(15px);
}

.mainPage {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background-color: #ffffff;
  overflow-x: hidden;
  overflow-y: scroll;
}

.maxWidth {
  max-width: 1200px;
  margin: 0 auto;
}

.homepage {
  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.contactPage {
  min-height: 600px;
}

.projectPage .tiken {
  color: #060606;
  border-color: #060606;
}

/* Full section component and dritte addon (tesla style third view)*/

.fullSection {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  box-sizing: border-box;
  margin-bottom: 100px;
}

.fullHeight {
  height: 100vh;
}

.fullSectionContent {
  z-index: 20;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.limitWidthContent {
  max-width: 1200px;
  padding: 0 5%;
}

@media only screen and (max-width: 900px) {
  .limitWidthContent {
    padding: 0 5%;
  }
}

.dritteTop {
  height: 300px;
}

.dritteBottom {
  min-height: 250px;
}

@media only screen and (min-height: 600px) {
  .dritteSection {
    height: 100vh;
  }

  .dritteTop {
    height: 100% !important;
  }

  .dritteBottom {
    height: 25%;
  }
}

@media only screen and (min-height: 600px) and (max-width: 450px) {
  .dritteSection {
    height: 100vh;
  }

  .dritteTop {
    height: 50% !important;
  }

  .dritteBottom {
    height: 50%;
    min-height: 300px;
  }
}

/* Social Box */

.socialBox {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.socialItem {
  width: 50px;
  height: 50px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s ease;
  cursor: pointer;
  border-radius: 30px;
}

.socialItem:hover {
  background: rgba(189, 202, 251, 1);
}

/* Footer Component */

.footer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 2% 50px 2%;
  box-sizing: border-box;
  background: linear-gradient(
    0deg,
    rgba(165, 131, 243, 0.1) 0%,
    rgba(133, 110, 189, 0.05) 70%,
    rgba(255, 255, 255, 1) 100%
  );
  min-height: 300px;
}

.footerContent {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  max-width: 1200px;
}

.footerFirstRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column: 1/2;
  grid-row: 1/2;
}

.footerMainMenu {
  grid-column: 1/2;
  grid-row: 2/3;
  display: flex;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.footerMainMenu h4 {
  margin: 5px 10px;
  cursor: pointer;
}

.footerEnd {
  grid-column: 1/2;
  grid-row: 3/4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footerEndMenu {
  display: flex;
}

.footerEndMenu h6 {
  margin: 5px 10px;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .footerFirstRow {
    flex-direction: column;
  }

  .footerEnd {
    width: 100%;
    justify-content: center;
  }
}

/* Service Page Componeng */

.servicePage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.servicePage .socialPostContainer {
  overflow: visible;
}

.servicesPage {
  padding-top: 100px;
}

/* Case History / Projects page */

.caseHistoryPage {
  padding-top: 100px;
}

/* Milanote */

.milanoteContainer {
  margin-top: 80px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.header-right {
  opacity: 0;
}
