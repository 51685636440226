.vierCardModule {
  position: relative;
  width: 100%;
  height: 450px;
  box-sizing: border-box;
  margin: 0px;
}

.vierCardContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 900px) and (min-width: 451px) {
  .vierCardModule {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100vw;
  }

  .vierCardContent {
    width: 160% !important;
    padding: 2%;
  }

  .vierCard {
    width: 25% !important;
    margin: 0 1%;
  }
}

@media only screen and (max-width: 450px) and (min-height: 600px) {
  .vierCardModule {
    width: 100vw;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .vierCardContent {
    width: 300%;
    padding: 0 2%;
  }

  .vierCard {
    width: 25%;
    margin: 0 1%;
  }
}

.vierCard {
  position: relative;
  width: 23%;
  height: 100%;
  overflow: hidden;
  border-radius: 30px;
  box-sizing: border-box;
  transition: all 0.6s ease;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.vierCard:hover {
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transform: scale(1.02);
}

/*.vierCard:hover .backImg {
  filter: blur(3px) brightness(100%);
}*/

.vierCard .backImg {
  position: absolute;
  height: 112%;
  top: -6%;
  z-index: 5;
  transition: all 0.6s ease;
}

.filterBlur {
  position: absolute;
  z-index: 10 !important;
  position: absolute;
  -webkit-mask-image: -webkit-linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 20%,
    rgba(188, 188, 188, 0) 40%,
    rgba(0, 0, 0, 0) 100%
  );
  -webkit-filter: blur(3px) brightness(200%) !important;
}

.projectInfo {
  z-index: 20;
  width: 100%;
  position: absolute;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vierCard .projectLogo {
  position: absolute;
  bottom: 25px;
  z-index: 20;
  width: 80%;
  left: 10%;
}

.vierCardLast {
  border: 2px solid rgba(206, 208, 237, 1);
  box-shadow: none !important;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgb(235, 230, 248, 1) 90%
  );
}

.vierCardLast .filterBlurImg {
  -webkit-filter: blur(3px) brightness(100%) saturate(0) !important;
  opacity: 0.2;
}

.vierCardLast:hover {
  border: 3px solid rgba(206, 208, 237, 1);
}

.vierCardLast h3 {
  z-index: 20;
  position: absolute;
  bottom: 50%;
  left: 20px;
}

.vierCircleButton {
  position: absolute;
  z-index: 50;
  top: 50%;
  left: 20px;
  transition: all 0.6s ease;
}

.vierCard:hover .vierCircleButton {
  left: 40%;
}

.vierCard:hover .circleBtnIcon {
  background-color: #060606;
  color: #f6f6f6;
  border-color: #060606;
}

/* vierCards on projects page / case history */

.projectsCards {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin: 0px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.projectsCards .vierCard {
  width: 100% !important;
  height: 400px;
}

.projectTitle {
  color: #fff;
}

@media only screen and (max-width: 900px) and (min-width: 451px) {
  .projectsCards {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 450px) {
  .projectsCards {
    grid-template-columns: 1fr;
  }
  .projectsCards .vierCard {
    height: 250px !important;
  }
}
